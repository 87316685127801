<template>
  <div class="money">
    <h1 class="count">
      {{ amount }}<span class="unit">{{ $tc('common.unit', amount) }}</span>
    </h1>
    <h2 class="second-title">{{ $t('recharge.amount') }}</h2>
    <ul class="num-list">
      <li
        v-for="({ select, id }, index) in rechargeAmountList"
        :key="id"
        :class="{ active: select }"
        @click="selectHandler(select, id, index)"
      >
        <span class="count">{{ id }}</span>
        <span>{{ $tc('common.unit', id) }}</span>
      </li>
    </ul>
    <h2 class="second-title">{{ $t('recharge.otherAmount') }}</h2>
    <MiInput
      type="number"
      v-model.number="count"
      :placeholder="$t('recharge.palceholder')"
      @focus="fixed = false"
      @blur="blurHandle"
    />
    <MiFooter
      :fixed="fixed"
      :disabled="disabled"
      :content="$t('common.rechargeBtn')"
      @click="recharge"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { regexp, zhCN } from '@/utils'
import {
  RECHARGE_UPDATE_ID,
  RECHARGE_UPDATE_AMOUNT_LIST
} from '@/store/mutations'
import { MiInput, MiFooter } from '@/components'

export default {
  components: {
    MiInput,
    MiFooter
  },
  data() {
    return {
      count: undefined,
      fixed: true
    }
  },
  computed: {
    ...mapState({
      id: state => state.recharge.id,
      userId: state => state.about.userInfo?.userId,
      rechargeAmountList: state => state.recharge.rechargeAmountList
    }),
    amount() {
      const { id, count } = this

      return id || count || 0
    },
    disabled() {
      const { rechargeAmountList, count, id } = this

      return !(count || rechargeAmountList.some(v => v.id === id))
    }
  },
  created() {
    if (!this.rechargeAmountList.length) {
      this.$store.dispatch('rechargeGetTypes')
    }
  },
  methods: {
    blurHandle() {
      setTimeout(() => {
        this.fixed = true
      }, 10)
    },
    selectHandler(select, id, index) {
      this.count = undefined
      this.$store.commit(RECHARGE_UPDATE_ID, select ? 0 : id)
      this.$store.commit(RECHARGE_UPDATE_AMOUNT_LIST, index)
    },
    validate() {
      const { count } = this
      const { integer, decimal } = regexp

      return new Promise((resolve, reject) => {
        if (!zhCN && count < 30) {
          reject(this.$t('recharge.warn'))
        } else if (count > 10000) {
          reject(this.$t('recharge.overAmount'))
        } else if (!zhCN && !integer.test(count)) {
          reject(this.$t('recharge.integer'))
        } else if (decimal.test(count)) {
          resolve()
        } else {
          reject(this.$t('recharge.overPoint'))
        }
      })
    },
    async recharge() {
      const {
        id,
        count,
        userId,
        $route: {
          query: { type }
        }
      } = this

      if (id || count) {
        if (count) {
          try {
            await this.validate()
          } catch (e) {
            this.$toast(e, 2500)
            return
          }
        }

        this.$http[`${type}Recharge`]({
          userId,
          orderFee: `${id || count}`
        })
      }
    }
  },
  watch: {
    count(val) {
      if (val > 0) {
        this.$store.commit(RECHARGE_UPDATE_ID, 0)
        this.$store.commit(RECHARGE_UPDATE_AMOUNT_LIST)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.money {
  height: 100%;
  padding: 36px 40px 90px;
  .count {
    font-weight: bold;
    font-size: 110px;
    text-align: center;
    font-family: Mitype;
    .unit {
      font-size: 48px;
      font-weight: normal;
    }
  }
  .second-title {
    padding-left: 44px;
    font-size: 36px;
    color: #8c93b0;
    margin-bottom: 39px;
  }
  .num-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    li {
      width: 320px;
      height: 218px;
      @include round(24px);
      margin: 0 20px 24px 0;
      text-align: center;
      line-height: 218px;
      background: rgba(13, 132, 255, 0.1);
      color: $theme-color;
      font-weight: bold;
      span {
        font-weight: 500;
        font-size: 38px;
      }
      .count {
        font-family: Mitype;
        font-weight: 600;
        font-size: 66px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    li.active {
      color: $white-color;
      background-color: $theme-color;
    }
  }
  .mi-input {
    margin-bottom: 300px;
  }
  .margin {
    margin-top: 90px;
  }
}
</style>
