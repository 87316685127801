<template>
  <div class="login-container">
    <div class="body">
      <MiTitle :title="$t('docTitle.aboutTitle')" />
      <p class="desc">{{ $t('login.desc') }}</p>
      <div class="mibi-icon"></div>
      <div class="btn-login">
        <a href="/login"
          ><mi-button>{{ $t('login.btnText') }}</mi-button></a
        >
        <div class="register">
          <a href="https://account.xiaomi.com/pass/register">
            <em class="icon reg"></em>
            {{ $t('login.register') }}
          </a>
          <span class="line"></span>
          <a href="https://account.xiaomi.com/pass/forgetPassword">
            <em class="icon pwd"></em>
            {{ $t('login.password') }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer">
      <em class="icon"></em>
      {{ $t('login.footer') }}
    </div>
  </div>
</template>

<script>
import { MiButton, MiTitle } from '@/components'

export default {
  components: {
    MiButton,
    MiTitle
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  @extend .mi-flex;
  flex-direction: column;
  justify-content: space-between;
  .body {
    .desc {
      padding-left: 78px;
      margin-top: 35px;
      font-size: 38px;
      color: $color-text-secondary;
    }
    .mibi-icon {
      height: 696px;
      @include bg-image('login');
      margin: 68px 0;
    }
    .btn-login {
      padding: 0 80px;
      .register {
        @extend .mi-flex-center;
        font-size: 36px;
        color: #000a1f;
        margin-top: 65px;
        span {
          @extend .mi-align-center;
          .icon {
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }
          .reg {
            @include bg-image('vector');
          }
          .pwd {
            @include bg-image('pwd');
          }
        }
        .line {
          width: 1px;
          height: 40px;
          background: #d0d0d0;
          margin: 0 65px;
        }
      }
    }
  }
  .footer {
    @extend .mi-flex-center;
    font-size: 36px;
    color: #8c93b0;
    margin-bottom: 66px;
    .icon {
      width: 33px;
      height: 36px;
      @include bg-image('icon');
      margin-right: 12px;
    }
  }
}
</style>
